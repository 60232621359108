<template>
    <div>
        <header>
            <div class="ls-card">
                <el-page-header @back="$router.go(-1)" content="编辑签证" />
            </div>
		</header>	
		<el-card class="acea-row row-middle">
			<div class="topInfo">
				<div class="order_info_top">
					<div class="text_info">
						<div class="order_no">{{formValidate.order_no}}</div>
						<div>-</div>
						<div class="name">{{formValidate.phone}}</div>
						<div>-</div>
						<div class="status">{{formValidate.status_text}}</div>
					</div>
				</div>
				<div class="info_content">
					<div class="info-item">
						<div class="label">联系人:</div>
						<el-input style="width: 200px;" maxlength="11" v-model="formValidate.username"></el-input>
					</div>
					<div class="info-item">
						<div class="label">电话号码:</div>
						<el-input style="width: 200px;" maxlength="11" v-model="formValidate.phone"></el-input>
					</div>
					<div class="info-item">
						<div class="label">入境日期:</div>
						<el-date-picker editable v-model="formValidate.date" type="date" @on-change="editdate" placeholder="选择日期">
						</el-date-picker>
					</div>
					<div class="info-item">
						<div class="label">行程名称:</div>
						<el-input style="width: 480px;" maxlength="50" v-model="formValidate.toruName"></el-input>
					</div>
					<div class="info-item">
						<div class="label">创建时间:</div>
						<div class="info">{{formValidate.create_time}}</div>
					</div>
				</div>
			</div>
		</el-card>
		<div class="center_info">
			<div style="width: 80%;">
				<el-steps :active="formValidate.process_status">
					<el-step :title="ite.text" :description="ite.annotation" v-for="(ite,inx) in formValidate.process" :key="inx"></el-step>
				</el-steps>	
			</div>
			<div class="divider">
				<div class="icon"></div>
				<div class="title">客户资料（{{formValidate.users.length}}人）</div>
			</div>
			<div class="el-divider_basic" v-for="(users,idx) in formValidate.users" :key="idx">
				<el-divider content-position="left"><span style=" color:#0256FF;font-size:22px">{{users.username}}-{{users.phone}}</span>
				</el-divider>
				<div class="content-an">
					<div class="left-img" :loading="loading">
						<div class="img-region">
							<vueCropperImg ref="cropper" @loading="loadingFun" :setWidth="1500" :setHeight="700"
								@postFile="postFile" />
						</div>
						<div style="height: 80px;">

						</div>
						<div class="demo-image">
							<div class="block" v-for="(fit,ix) in users.pics" :key="ix"
								@click="chooseImage(idx,fit.url,ix,users.id,fit.type)">
								<el-image :src="fit.url" fit="contain" style="width: 100px; height: 100px" />
								<span
									:class="chooseImgIndex==ix && chooseImgType==fit.type ? 'demonstration demonstration-red' : 'demonstration'">{{ fit.name }}</span>
							</div>
						</div>
						<div class="demo-image">
							<div class="block">
								<el-image 
								  style="width: 100px; height: 100px"
								  :src="formValidate._username_url" 
								  :preview-src-list="[formValidate._username_url]">
								</el-image>
								
								<span class="demonstration">名单</span>
								<el-button size="mini" type="warning" @click="re_username_url()">重新生成</el-button>
							</div>
							<div class="block">
								<el-image 
								  style="width: 100px; height: 100px"
								  :src="formValidate._schedule_url" 
								  :preview-src-list="[formValidate._schedule_url]">
								</el-image>
								<span class="demonstration">行程</span>
								<el-button size="mini" type="warning" @click="re_schedule_url()">重新生成</el-button>
							</div>
							<div class="block">
								<el-image 
								  style="width: 100px; height: 100px"
								  :src="formValidate.bx_img" 
								  :preview-src-list="[formValidate.bx_img]">
								</el-image>
								<span class="demonstration">保险</span>
							</div>
						</div>
						<div style="margin-left: 20px;">
							<el-button size="mini" type="danger" @click="reSubmitByOne(users.id)">重新推送-{{users.username}}</el-button>
						</div>
						<!-- <a v-if="users.urgent_marge_url" :href="users.urgent_marge_url" style="margin-left: 10px;" target="_blank">
							<Button type="primary">下载紧急联系人Excel</Button>
						</a> -->
					</div>
					<el-card class="right-info" v-if="formValidate.users.length>0">
						<el-form :model="formValidate" :rules="rules" ref="ruleForm" class="demo-ruleForm" label-position="left">
							<el-divider content-position="left"><span style=" color:#0256FF;font-size:22px">申请人</span></el-divider>
							<el-form-item label="姓名中文:" label-width="100px" required>
								<el-input v-model="users.username" maxlength="10" class="content_width" style="width: 140px;"></el-input>
								<el-input v-model="users.username_zh" maxlength="10" class="content_width" style="width: 140px;"></el-input>
							</el-form-item>
							<!-- <el-form-item label="姓名英文:" label-width="80px" required>
								<el-input v-model="users.username_en" class="content_width"></el-input>
							</el-form-item> -->

							<el-form-item label="手机号码:" label-width="100px"  required>
								<el-input v-model="users.phone" maxlength="11" class="content_width"></el-input>
							</el-form-item>

							<el-form-item label="性别：" label-width="100px" prop="sex" required>
								<el-radio-group v-model="users.sex">
									<el-radio :label="1">
										<span>男</span>
									</el-radio>
									<el-radio :label="2">
										<span>女</span>
									</el-radio>
								</el-radio-group>
							</el-form-item>

							<el-form-item label="身份证号码:" label-width="100px" required>
								<el-input v-model="users.idcard_no" maxlength="18" class="content_width"></el-input>
							</el-form-item>
							<el-form-item label="大通证号码:" label-width="100px" required>
								<el-input v-model="users.card_no" maxlength="20" class="content_width"></el-input>
							</el-form-item>
							<el-form-item label="出生日期" label-width="100px" required>
								<el-date-picker editable type="date" v-model="users.birth" placeholder="请选择出生日期"
									style="width: 180px" />
							</el-form-item>

							<el-form-item label="有效期" label-width="100px" required>
								<el-date-picker editable type="date" v-model="users.expiry" placeholder="请选择有效期"
									style="width: 180px" />
							</el-form-item>

							<el-form-item label="教育程度" label-width="100px" required>
								<el-select v-model="users.education" placeholder="请选择教育程度"  style="width: 140px;">
									<el-option v-for="(it,i) in education" :key="i" :label="it" :value="it">
									</el-option>
								</el-select>
								<!-- <el-input v-model="users.education_zh" maxlength="10" class="content_width" style="width: 140px;"></el-input> -->
							</el-form-item>
							<el-form-item label="职业" label-width="100px" required>
								<el-select v-model="users.career" placeholder="请选择职业" style="width: 140px;">
									<el-option v-for="(it,i) in career" :key="i" :label="it" :value="it">
									</el-option>
								</el-select>
								<!-- <el-input v-model="users.career_zh" maxlength="10" class="content_width" style="width: 140px;"></el-input> -->
							</el-form-item>
							<!-- <el-form-item label="出生省份" label-width="100px" required>
								<el-input v-model="users.birth_province"  maxlength="20" placeholder="请输入出生省份" style="width: 140px;"></el-input>
								<el-input v-model="users.birth_province_zh" maxlength="10" style="width: 140px;"></el-input>
							</el-form-item> -->
							<el-form-item label="公司名" label-width="100px" required>
								<el-input v-model="users.company_name"  maxlength="50" placeholder="请输入公司名"></el-input>
							</el-form-item>
							<el-form-item label="" label-width="100px" required>
								<el-input v-model="users.company_name_zh" maxlength="50"></el-input>
							</el-form-item>
							<el-form-item label="出生地" label-width="100px" required>
								<el-select v-model="users.birth_province_zh" placeholder="请选择出生地">
									<el-option v-for="(it,i) in provinces" :key="i" :label="it" :value="it">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="随行人员" label-width="100px">
								<el-input v-model="users.relative"  maxlength="10" placeholder="请输入姓名" style="width: 140px;"></el-input>
								<el-input v-model="users.relativeTitle"  maxlength="10" placeholder="请输入称谓" style="width: 140px;"></el-input>
							</el-form-item>
							<!-- <el-form-item label="地址:" label-width="100px" required>
								<el-input v-model="users.address"  maxlength="50" placeholder="请输入地址"></el-input>
							</el-form-item>
							<el-form-item label="地址繁体:" label-width="100px" required>
								<el-input v-model="users.address_zh"  maxlength="50" placeholder="请输入地址"></el-input>
							</el-form-item> -->

							<!-- <el-divider content-position="left"><span style=" color:#0256FF;font-size:22px">紧急联系人</span></el-divider>
							<el-form-item label="姓名中文:" label-width="100px" required>
								<el-input v-model="users.urgent_username" placeholder="请输入姓名"></el-input>
							</el-form-item>
							<el-form-item label="电话:" label-width="100px" required>
								<el-input v-model="users.urgent_phone" placeholder="请输入电话"></el-input>
							</el-form-item>
							<el-form-item label="性别：" label-width="100px" prop="sex" required>
								<el-radio-group v-model="users.urgent_sex">
									<el-radio :label="1">
										<span>男</span>
									</el-radio>
									<el-radio :label="2">
										<span>女</span>
									</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="关系" label-width="100px" required>
								<el-select v-model="users.relationship" placeholder="请选择关系">
									<el-option v-for="(it,i) in relationship" :key="i" :label="it" :value="it">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="地址:" label-width="100px" required>
								<el-input v-model="users.urgent_address" placeholder="请输入地址"></el-input>
							</el-form-item> -->
						</el-form>
					</el-card>
					<el-card class="right-info" v-if="formValidate.source == 0">
						<el-divider content-position="left"><span style=" color:#0256FF;font-size:22px">申请人图片类型</span></el-divider>
						<div class="demo-image">
							<div class="block" v-for="(fit,ix) in users.files" :key="ix">
								<el-image :src="fit.url" fit="contain" style="width: 100px; height: 100px" />
								<el-select v-model="fit.type" style="width:100px">
									<el-option v-for="(ite,idx) in selfMarks" :value="idx" :label="ite" :key="idx"></el-option>
								</el-select>
							</div>
						</div>
						<el-button  style="margin-left: 20px;" type="primary" @click="saveImgType(users.id,users.files,1)">
							保存申请人图片类型
						</el-button>
						<!-- <el-divider content-position="left"><span style=" color:#0256FF;font-size:22px">紧急联系人图片类型</span></el-divider>
						<div class="demo-image">
							<div class="block" v-for="(fit,ix) in users.urgent_files" :key="ix">
								<el-image :src="fit.url" fit="contain" style="width: 100px; height: 100px" />
								{{fit.type}}
								<el-select v-model="fit.type" style="width:100px">
									<el-option v-for="(ite,idx) in urgentMarks" :value="idx" :label="ite" :key="idx">{{ite}}</el-option>
								</el-select>
							</div>
						</div>
						<el-button  style="margin-left: 20px;" type="primary" @click="saveImgType(users.id,users.urgent_files,2)">
							保存紧急联系人图片类型
						</el-button> -->
					</el-card>
				</div>
			</div>
		</div>
		<div class="footer">
			<div class="button-footer">
				<el-button class="button_s" v-if="formValidate.status < 3" type="warning"
					@click="dialogFormVisible = true">
					审核
				</el-button>
				<el-button class="button_s" v-if="formValidate.status == 3" type="warning"
					@click="dialogFormVisible = true">
					重新审核
				</el-button>
				<el-button class="button_s" v-if="formValidate.status == 3" type="warning">
					送签中...
				</el-button>
				
				<el-button class="button_s" v-if="formValidate.status == -2 || formValidate.status == 4 || formValidate.status == 3" type="warning"
				 @click="reSubmitAll(1)">
					重新送签（不买保险）
				</el-button>

				<el-button class="button_s" v-if="formValidate.status == -2 || formValidate.status == 4 || formValidate.status == 3" type="danger"
				 @click="reSubmitAll(2)">
					重新送签（买保险）
				</el-button>
				
				<el-button class="button_s" type="primary" @click="saveOrder">
					保存
				</el-button>

				<el-button class="button_s" v-if="download_status==1" :loading="true" type="primary">
					打包中
				</el-button>
				
				<a v-if="formValidate.license_url_" :href="formValidate.license_url_" style="margin-left: 10px;" target="_blank">
					<el-button type="primary">许可证下载</el-button>
				</a>
				
				<a v-if="formValidate.excel_url" :href="formValidate.excel_url" style="margin-left: 10px;" target="_blank">
					<el-button type="primary">下载表格</el-button>
				</a>
				<a v-if="download_url" :href="download_url" style="margin-left: 10px;" target="_blank">
					<el-button type="primary">下载压缩包 </el-button>
				</a>
			</div>
		</div>
		
		<IsLoading ref="isLoadingRef" title=" " />
		<el-dialog
		  title="审核"
		  :visible.sync="dialogFormVisible"
		  width="30%"
		  :before-close="handleClose">
		  <el-radio-group v-model="auditStatus" button-style="solid">
		  	<el-radio label="通过"></el-radio>
		  	<el-radio label="拒绝"></el-radio>
		  </el-radio-group>
		  <el-input style="margin-top: 10px;" v-if="auditStatus=='拒绝'" v-model="auditReason" placeholder="请输入拒绝理由"></el-input>
		   <span slot="footer" class="dialog-footer">
		      <el-button @click="dialogFormVisible = false">取 消</el-button>
		      <el-button type="primary" @click="onAudit()">确 定</el-button>
		    </span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		mapState,
	} from 'vuex';
	import {
		orderInfoApi,
		saveOrderApi,
		getCateMark,
		updateAudit,
		editOrderApi,
		editImgTypeApi,
		reUsernameUrl,
		reScheduleUrl,
		re_submit_by_one,
		re_submit_all
	} from '@/api/visa/order';
	import config from '@/config'
	import { RequestPaging } from '@/utils/util'
	import IsLoading from './components/is-loading.vue'
	export default {
		name: 'Kinmen',
		components: {
			vueCropperImg: () => import('./components/vueCropperImg'),
			IsLoading
		},
		props: {},
		data() {
			return {
				active: ['0'],
				activeNames: ['0'],
				rules: {},
				loading: false,
				auditStatus: false,
				auditReason:'',
				dialogFormVisible: false,
				// fileUrl: Setting.apiBaseURL + "/file/uploadFile",
				header: {},
				width: 150,
				chooseImgIndex: 0,
				chooseImgType: 1,
				download_status: 0,
				download_url: '',
				education: [],
				career: [],
				provinces:[],
				relationship: [],
				formValidate: {
					order_no: '',
					phone: '',
					users: {
						pics: {},
					},
				},
				urgentMarks:[],
				selfMarks:[]
			}
		},
		computed: {
			...mapState('admin/layout', ['isMobile', 'menuCollapse']),
			labelPosition() {
				return this.isMobile ? 'top' : 'right'
			},
		},
		mounted() {
			if (this.$route.query.id) {
				this.getCate();
				this.getOrderInfo();
			}
		},
		methods: {
			
			re_username_url(){
				this.$refs.isLoadingRef.openDialog()
				reUsernameUrl({order_id:this.formValidate.id}).then(res => {
					this.$message({
					  message: '成功',
					  type: 'success'
					});
					this.$refs.isLoadingRef.closeDialog()
					return this.getOrderInfo();
				})
				.catch((res) => {
					this.$refs.isLoadingRef.closeDialog()
					return this.$message({
						type: 'error',
						message: '操作失败!'
					});
				}) 
			},
			
			re_schedule_url(){
				this.$refs.isLoadingRef.openDialog()
				reScheduleUrl({order_id:this.formValidate.id}).then(res => {
					this.$message({
					  message: '成功',
					  type: 'success'
					});
					this.$refs.isLoadingRef.closeDialog()
					return this.getOrderInfo();
				})
				.catch((res) => {
					this.$refs.isLoadingRef.closeDialog()
					return this.$message({
						type: 'error',
						message: '操作失败!'
					});
				}) 
			},
			
			reSubmitByOne(uid){
				let that = this;
				that.$confirm('此操作将重新推送资料至移民署, 是否继续?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					re_submit_by_one({order_id:that.formValidate.id,uid:uid}).then(res => {
						return that.$message({
						  message: '成功,请稍后刷新查看',
						  type: 'success'
						});
					})
					.catch((res) => {
						return that.$message({
							type: 'error',
							message: '操作失败!'
						});
					}) 
				})
			},
			
			reSubmitAll(type){
				let that = this;
				that.$confirm('此操作将重新推送资料至移民署, 是否继续?', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
					re_submit_all({order_id:that.formValidate.id,type:type}).then(res => {
						return that.$message({
						  message: '成功,请稍后刷新查看',
						  type: 'success'
						});
					})
					.catch((res) => {
						return that.$message({
							type: 'error',
							message: '操作失败!'
						});
					}) 
				})
			},
			
			handleClose(){
				this.dialogFormVisible = false
			},
			editdate(e){
				this.formValidate.date = e;
			},
			saveImgType(uid,files,type=1){
				let parm = {
					uid:uid,
					files:files,
					type:type
				}
				this.$refs.isLoadingRef.openDialog()
				editImgTypeApi(parm).then(res => {
					this.$message({
					  message: '保存成功',
					  type: 'success'
					});
					this.$refs.isLoadingRef.closeDialog()
					return this.getOrderInfo();
				})
				.catch((res) => {
					this.$refs.isLoadingRef.closeDialog()
					return this.$message({
						type: 'error',
						message: '操作失败!'
					});
				}) 
				
			},
			/**
			 * 获取标签
			 */
			getCate() {
				getCateMark().then(response => {
					console.log(response,'response')
					this.education = response.education;
					this.career = response.career;
					this.relationship = response.relationship;
					this.selfMarks = response.selfMarks;
					this.provinces = response.citys;
					this.urgentMarks = response.urgentMarks;
				});
			},

			//保存
			saveOrder() {
				let that = this;
				console.log(that.formValidate, 'that.formValidate')
				this.$refs.isLoadingRef.openDialog()
				saveOrderApi(that.formValidate)
					.then(async (res) => {
						this.$refs.isLoadingRef.closeDialog()
						return this.$message({
							type: 'success',
							message: '保存成功!'
						});
					})
					.catch((res) => {
						this.$refs.isLoadingRef.closeDialog()
						return this.$message({
							type: 'error',
							message: '操作失败!'
						});
					}) 
			},

			// 审核
			onAudit() {
				let that = this;
				let status = '';
				if(that.auditStatus == '通过'){
					status = 1
				}else if(that.auditStatus == '拒绝'){
					status = -1
				}else{
					return that.$message({
						type: 'error',
						message: '请选择审核状态!'
					});
				}
				updateAudit( {
					id:that.formValidate.id,
					status: status,
					audit:that.auditReason
				}).then(res => {
					that.dialogFormVisible = false;
					return that.getOrderInfo({id:that.formValidate.id})
				}).catch((res) => {
					that.dialogFormVisible = false;
					return that.$message({
						type: 'error',
						message: '操作失败!'
					});
				});
			},

			reduction() {
				let that = this;
				that.$confirm('此操作将资料还原至用户提交初始状态, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					reductionApi(that.$route.query.id)
						.then(async (res) => {
							that.$message({
								type: 'success',
								message: '还原成功!'
							});
							that.getOrderInfo();
						})
						.catch((res) => {
							that.$message({
								type: 'success',
								message: res.msg
							});
						})

				}).catch(() => {});
			},

			zip() {
				let that = this;
				that.download_status = 1;
				that.$confirm('是否确认下载?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					zipApi({
							id: that.formValidate.id,
						})
						.then(async (res) => {

						})
						.catch((res) => {})
				}).catch(() => {

				});
				setTimeout(function() {
					that.query_download()
				}, 5000);
			},

			query_download() {
				let that = this;
				if (that.download_status !== 2) {
					downloadApi({
							id: that.formValidate.id,
						})
						.then(async (res) => {
							that.download_status = 2;
							that.download_url = res.url;
						})
						.catch((res) => {
							setTimeout(function() {
								that.query_download()
							}, 1000);
						})
				}
			},

			createApply(uid) {
				this.$confirm('确认生成申请表?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.createPdf(uid);
				}).catch(() => {});
			},
			loadingFun(bool) {
				this.loading = bool
			},
			getOrderInfo() {
				let that = this;
				this.$refs.isLoadingRef.openDialog()
				orderInfoApi({id:that.$route.query.id})
					.then(async (res) => {
					this.$refs.isLoadingRef.closeDialog()
						that.formValidate = res;
						that.download_url = res.excel_zip;
						if (that.formValidate.users.length > 0 && that.formValidate.users[0].pics.length > 0) {
							setTimeout(() => {
								that.formValidate.users.forEach((item,index)=>{
									that.$refs.cropper[index].tihuan(0, that.formValidate.users[index].pics[0].url, 0,
										that.formValidate.users[index].id, that.formValidate.users[index].pics[0]
										.type, 1)
								})
							}, 1000)
						}
					})
					.catch((res) => {
						that.$refs.isLoadingRef.closeDialog()
						return that.$message({
							type: 'error',
							message: '获取详情失败!'
						});
					})
			},

			//基础资料信息
			postFile(data) {
				console.log(data,'postFiledata')
				this.formValidate.users[data.idx].pics[data.index].url = data.url;
				this.formValidate.users[data.idx].pics[data.index].src = data.src;
				this.$set(this.formValidate, 'users', this.formValidate.users)
				this.$set(this, 'formValidate', this.formValidate)
				this.chooseImage(data.idx, data.url, data.index, data.uid, data.type)
			},
			chooseImage(idx, url, index, uid, type) {
				this.chooseImgIndex = index;
				this.chooseImgType = type;
				this.$refs.cropper[idx].tihuan(idx, url, index, uid, type, 1)
			},

			createPdf(uid) {
				this.$refs.isLoadingRef.openDialog()
				createPdfApi({
						id: this.formValidate.id,
					})
					.then(async (res) => {
					this.$refs.isLoadingRef.closeDialog()
						this.formValidate = res.data;
						this.$message({
							type: 'success',
							message: '生成申请表成功!'
						});
					})
					.catch((res) => {
					this.$refs.isLoadingRef.closeDialog()
						return this.$message({
							type: 'error',
							message: '操作失败!'
						});
					})
			}
		},
	}
</script>

<style lang="scss" scoped>
	.demo-image {
		display: block;

		.block {
			padding: 30px 0;
			text-align: center;
			border-right: 1px solid #eff2f6;
			display: inline-block;
			width: 25%;
			box-sizing: border-box;
			vertical-align: top;

			.demonstration {
				display: block;
				color: #8492a6;
				font-size: 14px;
				margin-bottom: 20px;
			}

			.demonstration-red {
				color: #F56C6C;
			}
		}

	}

	.demo-spin-col {
		height: 100px;
		position: relative;
		border: 1px solid #eee;
	}

	.topInfo {
		width: 98%;
		border: 3px solid #8e9cff;
		border-radius: 10px;
		background: linear-gradient(to bottom, #8e9cff 0%, #8e9cff 50px, rgba(0, 255, 0, 0) 10%, rgba(0, 0, 0, 0) 100%);

		.info_content {
			display: flex;
			min-height: 60px;
			flex-wrap: wrap;
			align-items: center;
			margin: 10px;

			.info-item {
				display: flex;
				width: 32%;
				margin-left: 20px;

				.label {
					margin-top: 13px;
					margin-right: 10px;
					width: 12%;
				}
				.info{
					margin-top: 13px;
				}
			}
		}

		.order_info_top {
			height: 50px;
		}

		.order_info_top {
			height: 50px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.text_info {
				display: flex;
				align-items: center;
				color: white;
				font-weight: bold;
				margin-left: 20px;

				div {
					margin-right: 10px;
				}
			}

			.operate {
				div {
					padding: 5px 30px 5px 30px;
					background: white;
					border-radius: 4px;
					cursor: pointer;
					margin-right: 20px;
				}
			}
		}
	}

	.center_info {
		margin-top: 30px;

		.divider {
			margin-top: 30px;
			display: flex;
			align-items: center;

			.icon {
				width: 2px;
				height: 15px;
				background: #9119ff;
				margin-right: 10px;
			}

			.title {
				font-size: 14px;
				font-weight: bold;
			}
		}

		.el-divider_basic {
			margin-top: 10px;
			.xian_title {
				display: flex;
				align-items: center;

				.line {
					width: 50px;
					height: 1px;
					background: #dedede;
				}

				.line:nth-child(3) {
					width: 82% !important;
				}

				.text {
					font-size: 18px;
					margin-left: 10px;
					margin-right: 10px;
				}
			}

			.content-an {
				display: flex;
				min-height: 400px;

				.left-img-none {
					width: 550px;
				}

				.ivu-input-wrapper {
					width: 50%;
				}

				.ivu-select {
					width: 50%;
				}

				.ivu-form-item {
					width: 120%;
				}

				.left-img {
					width: 550px;
					min-width: 600px;
					background: #fbfbfb;
					border-radius: 10px;

					.table-button {
						display: flex;
						justify-content: space-between;
						height: 40px;
						align-items: center;

						.button-left {
							display: flex;
							padding-left: 20px;

							.img-button {
								padding-top: 5px;
								padding-bottom: 5px;
								padding-left: 10px;
								padding-right: 10px;
								border-radius: 4px;
								border: 1px solid #e8e8e8;
								margin-right: 10px;
								cursor: pointer;
							}

							.active {
								background: #5d5267;
								color: white;
							}
						}

						.button-right {
							width: 200px;
							display: flex;
							align-items: center;
							flex-direction: row-reverse;
							margin-right: 10px;

							.img-button {
								padding-top: 5px;
								padding-bottom: 5px;
								padding-left: 10px;
								padding-right: 10px;
								border-radius: 4px;
								border: 1px solid #e8e8e8;
								margin-right: 10px;
								cursor: pointer;
							}
						}
					}

					.img-region {
						width: 95%;
						height: 350px;
						margin: auto;
						background: antiquewhite;
					}

					.bottom-button {
						display: flex;
						height: 65px;
						border-top: 1px solid #f5f5f5;
						margin-top: 25px;
						justify-content: space-around;

						.img-button {
							width: 25%;
							height: 60px;
							line-height: 60px;
							text-align: center;
							border-right: 1px solid #f5f5f5;
							cursor: pointer;
						}
					}
				}

				.right-info {
					margin-left: 20px;
					min-width: 500px;

					::v-deep .Input--mini {
						padding-right: 10px;
					}

					::v-deep .FormItem__content {
						display: flex;
					}

					::v-deep .ivu-form-item-label {
						width: 90px;
					}
				}
			}
		}
	}

	.pdf_bg {
		text-align: center;
		align-items: center;
		height: 100%;
		width: 100%;
		line-height: 345px;
		// background: transparent;
		// color: #fff;
		// background-color: rgba(28, 49, 78, 0.35);
		cursor: pointer;
	}

	.footer {
		height: 60px;
		background-color: #fff;
		text-align: right;
		width: 100%;
		padding: 15px;
	}

	.button-footer {
		margin-right: 12%;

		.button_s {
			margin-right: 10px;
		}
	}
</style>